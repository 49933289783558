import { useRoutes } from 'react-router-dom';
import { MainLayout } from '../layout/MainLayout';
import { Authlayout } from '../layout/Authlayout';
import Home from '../pages/HomeMain';
import PackageDetails from '../pages/PackageDetails';
import PackageListing from '../pages/PackageListing';
import ProductReview from '../pages/ProductReview/ProductReview';
import ReviewDetails from '../pages/ReviewDetails';
import ReviewPay from '../components/ReviewPay/ReviewPay';
import ReviewAndPay from '../pages/ReviewAndPay';
import Payment from '../pages/payment/payment';
import PhotoGallery from '../pages/PhotoGallery/PhotoGallery';
import TripRequestConfirmation from '../pages/TripRequestConfirmation';
import Conversational from '../pages/Conversational';
import SelectedVacationSpot from '../components/conversational/SelectedVacationSpot/SelectedVacationSpot';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndCondition from '../pages/TermsAndCondition';

const Routes = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <Authlayout />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/product-review',
          element: <Authlayout />,
          children: [
            {
              path: '/product-review',
              element: <ProductReview />
            }
          ]
        },
        {
          path: '/explore/product-details',
          element: <Authlayout />,
          children: [
            {
              path: '/explore/product-details/:package-id',
              element: <PackageDetails />
            }
          ]
        },
        {
          path: '/review-details',
          element: <Authlayout />,
          children: [
            {
              path: '/review-details',
              element: <ReviewDetails />
            }
          ]
        },
        {
          path: '/review-pay',
          element: <Authlayout />,
          children: [
            {
              path: '/review-pay',
              element: <ReviewPay />
            }
          ]
        },
        {
          path: '/explore/*',
          element: <PackageListing />
        },
        {
          path: '/review-pay',
          element: <ReviewAndPay />
        },
        { path: '/payment', element: <Payment /> },
        { path: '/photo-gallery', element: <PhotoGallery /> },
        { path: '/trip-confirmation', element: <TripRequestConfirmation /> }
      ]
    },
    {
      path: '/auth',
      element: <MainLayout />,
      children: []
    },
    {
      path: '/conversational',
      element: <Conversational />
    },
    {
      path: '/vacation-spot',
      element: <SelectedVacationSpot />
    },
    {
      path: '/termsncondition',
      element: <TermsAndCondition />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    }
  ]);

  return routes;
};

export default Routes;
