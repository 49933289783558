/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import '../Header/Header.css';
import {
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { CloseRounded, Search } from '@mui/icons-material';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { convertCommaToAnd, IndianZones } from '../../Helper/global';

const StyledTextField = styled(TextField)((theme) => ({
  width: '100% !important',
  backgroundColor: 'white',
  borderRadius: '20px',
  height: '40px',
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '9.5px 1px !important'
  }
}));

const CustomTypography = (props) => <Typography {...props} sx={{ fontFamily: 'inherit' }} />;

const DropdownList = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '90%',
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  zIndex: 9999,
  marginTop: theme.spacing(1),
  borderRadius: '10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper
}));

const StyledListItem = styled(ListItem)({
  padding: '8px 16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  '&:last-child': {
    borderBottom: 'none'
  }
});

const SearchDestination = () => {
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const getSearchResults = async () => {
    try {
      const searchRes = await api.get(
        `${BASE_URL}${API_URL.SEARCH_DESTINATION}?destination=${searchText}`
      );
      const { status, data, message } = searchRes;
      if (status) {
        setSearchData(data);
        setShowDropdown(true);
      } else {
        toast.error(data, { autoClose: 3000 });
        setShowDropdown(false);
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (searchText && searchText.length > 2) {
      getSearchResults();
    } else {
      setShowDropdown(false);
    }
  }, [searchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClearSearch();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const findZone = (subDestination) => {
    const normalizedSubDestination = subDestination.toLowerCase().replace(/ and /g, ' & ');
    for (const [zone, destinations] of Object.entries(IndianZones)) {
      if (
        destinations.some((destination) => destination.toLowerCase() === normalizedSubDestination)
      ) {
        return `${zone.toLowerCase()}-india`;
      }
    }
    return null;
  };

  const handleLocationClick = (mainDestination, subDestination) => {
    let url;
    if (mainDestination === 'INDIA') {
      const zone = findZone(subDestination);
      if (zone) {
        url = `/explore/india/${zone}/${convertCommaToAnd(subDestination)}`;
      } else {
        url = `/explore/india/${convertCommaToAnd(subDestination)}`;
      }
    } else {
      url = `/explore/world/${mainDestination.toLowerCase()}/${convertCommaToAnd(subDestination)}`;
    }
    navigate(url, { replace: true });
    handleClearSearch();
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchData([]);
  };

  return (
    <Grid item xs={7} sm={4} md={4} lg={4} xl={6} className="search-box">
      <StyledTextField
        autoComplete="off"
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search 'Australia'"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">{/* <Search /> */}</InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              {showDropdown && searchData.length > 0 ? (
                <IconButton onClick={handleClearSearch}>
                  <CloseRounded sx={{ cursor: 'pointer' }} />
                </IconButton>
              ) : (
                <Search />
              )}
            </InputAdornment>
          )
        }}
      />
      {showDropdown && searchData.length > 0 && (
        <DropdownList ref={dropdownRef}>
          <List>
            {searchData.map((item, index) => (
              <StyledListItem
                button
                key={index}
                onClick={() => handleLocationClick(item.mainDestination, item.subDestination)}>
                <ListItemText
                  primary={item.subDestination}
                  primaryTypographyProps={{ component: CustomTypography }}
                />
              </StyledListItem>
            ))}
          </List>
        </DropdownList>
      )}
    </Grid>
  );
};

export default SearchDestination;
