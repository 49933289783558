import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BreadCrumbs.css';
import HomeIcon from '../../../assest/common/breadcrumbs/home.svg';
import { IconButton } from '@mui/material';

const Breadcrumbs = ({ packageDetails }) => {
  const navigate = useNavigate();

  return (
    <div className="breadcrumbs">
      <ul>
        <li>
          <IconButton onClick={() => navigate('/')} style={{ padding: 0 }}>
            <img src={HomeIcon} alt="homeicon" />
          </IconButton>
        </li>
        <li>
          <button onClick={() => navigate('/explore')}>
            {packageDetails.country === 'India' ? 'India' : 'World'}
          </button>
        </li>
        <li>
          <button onClick={() => navigate('/explore')}>
            {packageDetails.country === 'India' ? packageDetails.state : packageDetails.country}
          </button>
        </li>
        <li>
          <button className="no-pointer">
            {packageDetails?.places?.length > 0 ? packageDetails.places[0].Place : 'Place'}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
